<template>
 <div class="content-box">
  <div class="container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>协同管理</el-breadcrumb-item>
      <el-breadcrumb-item>协同服务</el-breadcrumb-item>
      <el-breadcrumb-item>运维方案</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button
      class="reBack"
      v-show="this.plannId !== undefined"
      @click="$router.push('/Data/contract')"
      type="primary"
      >返回</el-button
    >
    <div class="button-gutter">
      <el-button type="primary" @click="dialogVisible = true"
        >上传运维方案</el-button
      >
      <el-button type="primary" @click="GOliga">LIGA运维平台</el-button>
    </div>

    <el-table :data="tableData" border max-height="600">
      <el-table-column align="center" width="50" label="序号">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>

      <el-table-column
        prop="operationService.contractName"
        align="center"
        label="合同名称"
      >
      </el-table-column>
      <el-table-column
        prop="operationService.contractOperationName"
        align="center"
        label="文件名称"
      >
      </el-table-column>
      <el-table-column
        prop="operationService.timeCreated"
        align="center"
        label="传输时间"
      >
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            style="margin-right: 12px"
            size="mini"
            @click="Dowload(scope.row, scope.$index)"
            plain
          >
            查看
          </el-button>
          <el-popconfirm
            confirm-button-text="确定"
            cancel-button-text="取消"
            confirm-button-type="danger"
            icon="el-icon-info"
            icon-color="red"
            title="确定删除吗？"
            @confirm="deleteFile(scope.row, scope.$index)"
          >
            <el-button type="danger" slot="reference" size="mini" plain
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="上传运维方案"
      :visible.sync="dialogVisible"
      :before-close="Cancel"
    >
      <el-form :model="palnFrom">
        <el-form-item label="所属合同">
          <el-select v-model="palnFrom.contractId" placeholder="请选择">
            <el-option
              v-for="(item, index) in contractOptions"
              :key="index"
              v-bind:label="item.label"
              v-bind:value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传资料">
          <el-upload
            class="upload-demo"
            :action="actions"
            :headers="tokenData"
            :before-upload="uploads"
            :on-preview="handlePreview"
            :on-progress="newhand"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="1"
            ref="upload"
            :on-exceed="handleExceed"
            :on-success="successw"
            accept=".pdf, .JPEG, .jpg"
          >
            <el-button type="primary">文件上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传.pdf/.JPEG/.jpg文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Cancel">取 消</el-button>
        <el-button type="primary" @click="SendData(palnFrom)">确 定</el-button>
      </div>
    </el-dialog>
    <el_pagination
      :total="total"
      :num="num"
      @page-limit="page_limit"
    ></el_pagination>
   </div>
  </div>
</template>

<script>
import { planDownload } from "@/api/file";
import {
  contractlist,
  planSearch,
  planCreate,
  planDlete,
} from "@/api/document";

export default {
  name: "Plan",
  components: {},
  data() {
    return {
      actions: process.env.VUE_APP_BASE_API + `/file/upload`,
      dialogVisible: false,
      contractOptions: [],
      tableData: [],
      palnFrom: {},
      pageSize: 10,
      currentPage: 1,
      total: 0,
      num: 0,
      plannId: "",
    };
  },
  computed: {
    baseApi() {
      return process.env.VUE_APP_BASE_API;
    },
    tokenData() {
      return {
        Authorization: window.localStorage.getItem("token"),
      };
    },
  },

  created() {
  
  },
  mounted() {
    this.plannId = this.$route.query.id;
    //console.log(this.$route.query.id)
      this.getFileList();
    this.getContractLsit();
  },
  methods: {
    // 分页子集传过来的数据  page = 页码  limit = 条数
    // 分页子集传过来的数据  page = 页码  limit = 条数
    page_limit(list) {
      console.log(list);
      let { page, limit } = list;
      this.currentPage = page;
      this.pageSize = limit;
      this.tableData = [];
      this.getFileList();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    async getFileList() {
      let limit = this.pageSize;
      let page = this.currentPage;
      let contractId = this.plannId;
      if (contractId == "" || contractId == undefined) {
        let  res  = await planSearch({limit,page})
        if(res.code==200){

          this.tableData = res.data
          this.total =res.total
          this.num = parseInt(this.total / limit + 1);
        }
      } else if (!contractId == undefined || !contractId == "") {
            let  res  = await planSearch({limit,page,contractId})
        if(res.code==200){

          this.tableData = res.data
          this.total =res.total
          this.num = parseInt(this.total / limit + 1);
        }
      }
    },

    async getContractLsit() {
      let res = await contractlist();
      if (res.code == 200) {
           console.log(res.data);

          
        let list = [];
        res.data.forEach((item) => {
          let data = {};
          data.label = item.contractManagement.contractName;
          data.value = item.contractManagement.id;
          list.push(data);
        });
        this.contractOptions = list;
        // console.log(list);
      }
    },

    async deleteFile(row, $index) {
      let id = row.operationService.id;
      console.log(id);
      const res = await planDlete({
        id,
      });
      if (res.code === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      await this.getFileList();
    },
    async downloadFile(id) {
      const res = await fileDownload(id);
      download(res, "test");
    },
    Cancel() {
      this.dialogVisible = false;
    },
    async SendData(palnFrom) {
      let res = await planCreate(this.palnFrom);
      if (res.code == 200) {
        this.$message.success("上传成功");
        this.dialogVisible = false;
        this.palnFrom = {};
        this.getFileList();
      } else {
        this.$message.error("上传失败");
        this.dialogVisible = false;
        this.palnFrom = {};
      }
    },
    uploads(file, fileList) {
      this.fileList = fileList;
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf", "jpg", "JPEG"];
      const isSuffix = whiteList.indexOf(fileSuffix.toLowerCase()) === -1;
      const isLt10M = file.size / 1024 / 1024 > 10;
      console.log("this.fileList:", this.fileList);
      if (isSuffix) {
        this.$message.error("上传文件只能是 pdf、jpg,JPEG格式");
        const currIdx = this.fileList.indexOf(file);
        this.fileList.splice(currIdx, 1);
        return;
      }
      if (isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB");
        const currIdx = this.fileList.indexOf(file);
        this.fileList.splice(currIdx, 1);
        return;
      }
    },
    handlePreview(file) {
      console.log(file);
    },
    newhand(event, file, fileList) {
      console.log(event);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    successw(response, file, fileList) {
      this.loading = true;
      console.log(response);
      if (response.code == 200) {
        this.disabled = false;
        this.loading = false;
        this.palnFrom.contractOperationId = response.data;
        this.$message({
          message: "上传成功",
          type: "success",
        });
        console.log(response.data);
      } else {
        this.$message.error("上传失败");
      }
    },

    async Dowload(row, $index) {
      let id = row.operationService.id;

      const res = await planDownload({ id });
      let url = window.URL.createObjectURL(new Blob([res]));
      // const url = new Blob([res])
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = row.operationService.contractOperationName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    GOliga() {
      let href = "http://119.84.46.35:8801/login";
      // window.location.href = link
      window.open(href, "_blank");
    },
  },
};
</script>


<style lang="scss" scoped>
.button-gutter {
  margin: 20px 0;
}
.reBack {
  float: right;

  margin-top: 10px;
}
</style>